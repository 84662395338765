<template>
  <div class="ss-backpage">
    <h1>Steve Spriggs <span class="ss-highlight">Résumé</span></h1>

    <div class="ss-history">
      <h2 class="ss-muted">Career History</h2>
      <ul class="ss-focus">
        <li>
          <div class="ss-title">
            Senior UX Engineer <br />
            <span class="ss-muted">Red Hat</span>
          </div>
          <div class="ss-date">Fall 2021 &ndash; Present</div>
        </li>
        <li>
          <div class="ss-title">
            Web Application Developer and DevOps Engineer <br />
            <span class="ss-muted">West Virginia University</span>
          </div>
          <div class="ss-date">Fall 2008 &ndash; Fall 2021</div>
        </li>
        <li>
          <div class="ss-title">
            Board Member <br />
            <span class="ss-muted">Friends of Deckers Creek</span>
          </div>
          <div class="ss-date">Fall 2019 &ndash; Present</div>
        </li>
        <li>
          <div class="ss-title">
            Owner <br /><span class="ss-muted">S3 Consulting</span>
          </div>
          <div class="ss-date">Fall 2011 &ndash; Present</div>
        </li>
        <li>
          <div class="ss-title">
            E-Commerce Developer and Consultant <br /><span class="ss-muted"
              >Appalachian Offroad MC</span
            >
          </div>
          <div class="ss-date">Fall 2001 &ndash; Fall 2014</div>
        </li>
        <li cl>
          <div class="ss-title">
            Web Developer <br /><span class="ss-muted"
              >West Virginia University</span
            >
          </div>
          <div class="ss-date">Fall 2003 &ndash; Spring 2006</div>
        </li>
      </ul>
      <h2 class="ss-muted">Education</h2>
      <ul>
        <li>
          <div class="ss-title">
            <div class="ss-display-h3">West Virginia University</div>
            <span class="ss-muted"
              >Bachelor of Science <br />
              Management Information Systems</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
